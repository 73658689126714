import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import { alpha } from "@material-ui/core/styles/colorManipulator"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SidebarTestimonials from "./UI/SidebarTestimonials"
import headerImg from "../assets/images/outdoorliving.jpg"

const Background = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${headerImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 100%;
  color: white;
  padding: 12rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 20%;
  }
  @media only screen and (max-width: 600px) {
    backgroundposition: 50% 20%;
    background-attachment: scroll;
  }
`

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: "2.75rem",
    fontWeight: 600,
    padding: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  h3: {
    fontSize: "3rem",
    color: theme.palette.common.muted,
    fontWeight: 600,
    [theme.breakpoints.only("xl")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
  },
  h4: {
    fontSize: "3rem",
    fontWeight: 500,
    color: theme.palette.secondary.main,
    [theme.breakpoints.only("xl")]: {},
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.25rem",
    },
  },
  body: {
    fontSize: "1.05rem",
    lineHeight: "1.75rem",
  },
  preHeader: {
    fontWeight: 500,
    marginBottom: ".25rem",
    color: "#646e73",
    fontSize: "1rem",
    textTransform: "uppercase",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    opacity: 0.8,
  },
  hr: {
    marginTop: ".6em",
    backgroundColor: theme.palette.background.paper,
    maxWidth: "90%",
    marginBottom: "2.5rem",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.only("xl")]: {
      maxWidth: "100%",
    },
  },
  paper: {
    borderRadius: 0,
    border: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
    padding: "1rem",
  },
  cardTitle: {
    fontSize: "1.75rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    fontWeight: 600,
    textDecoration: "none",
    transition: "0.5s",
    "&:hover": {
      color: "#000",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.45rem",
    },
  },
  contactBtn: {
    borderRadius: 0,
    fontSize: "1.1rem",
    padding: ".5rem 2rem",
    width: "100%",
    transition: "0.1s",
    "&:hover": {
      color: "#000",
      backgroundColor: theme.palette.primary.main,
      transform: "scale(1.1)",
    },
  },
  contactSection: {
    marginTop: "1rem",
    borderRadius: 0,
    border: `3px solid ${theme.palette.secondary.main}`,
    padding: "2rem 4rem",
    backgroundColor: "transparent",
    lineHeight: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 2rem",
    },
  },
  contactSecText: {
    fontWeight: 500,
    fontSize: "1.1rem",
    color: theme.palette.common.muted,
  },
  promoOfferPaper: {
    borderRadius: 0,
    padding: ".5rem",
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-1.5rem",
      marginRight: "-1.5rem",
    },
  },
  promoBorder: {
    padding: "2.5rem",
    border: `3px solid ${theme.palette.common.muted}`,
    borderStyle: "dashed",
  },
  promoHeader: {
    color: theme.palette.common.muted,
    fontWeight: 700,
    fontSize: "2.5rem",
    lineHeight: "1",
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  promoSubHeader: {
    color: theme.palette.common.muted,
    fontWeight: 600,
    fontSize: "1.5rem",
  },
  promoButton: {
    marginTop: "1rem",
    width: "100%",
    height: "3.125rem",
    color: "#FFFFFF",
    borderRadius: 0,
    backgroundColor: theme.palette.common.muted,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.9)",
    },
  },
  textField: {
    width: "100%",
    borderRadius: 0,
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
  },
  floatLeft: {
    [theme.breakpoints.only("xl")]: {
      paddingLeft: "15rem",
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "5rem",
    },
  },
  floatRight: {
    [theme.breakpoints.only("xl")]: {
      marginLeft: "-2rem",
    },
    [theme.breakpoints.only("lg")]: {
      marginLeft: "-2rem",
    },
  },
}))

const StyledListItem = withStyles(theme => ({
  root: {
    transition: "transform 0.5s",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
      transform: "scale(1.025)",
    },
    "&.Mui-selected": {
      backgroundColor: "#000",
      color: "#fff",
      fontSize: "1.01rem",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#000",
      color: "#fff",
      transform: "scale(1.025)",
    },
  },
}))(ListItem)

export default function OutdoorLivingComponent() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"))
  const location = useLocation()

  let CustomListItem = ({ to, primary }) => (
    <>
      <StyledListItem
        button
        component={Link}
        to={to}
        selected={to === location.pathname}
      >
        <ListItemText primary={primary} />
      </StyledListItem>
      <Divider />
    </>
  )

  let ItemSpacer = () => (
    <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
      <Grid container>
        <Divider
          style={{
            width: "90%",
          }}
        />
      </Grid>
    </Grid>
  )

  return (
    <>
      <Background>
        <Typography variant="h1" className={classes.h1} align="center">
          OUTDOOR LIVING
        </Typography>
      </Background>
      <Container maxWidth="xl" style={{ paddingBottom: "5rem" }}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Container maxWidth="lg">
              <Grid
                container
                style={{ paddingTop: "4rem" }}
                className={classes.floatLeft}
              >
                <Grid item xs={12} lg={10} xl={11}>
                  <Container maxWidth="lg">
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          component="span"
                          className={classes.preHeader}
                          gutterBottom
                        >
                          We create beautiful
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h2" className={classes.h3}>
                          Outdoor living spaces
                        </Typography>
                        <Divider className={classes.hr} />
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
                <Grid item xs={12} lg={11} xl={11}>
                  <Container maxWidth="lg">
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Outdoor Living Project Management
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          className={classes.body}
                          paragraph
                        >
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Adipisci fuga incidunt nemo explicabo ipsam
                          reiciendis eveniet officiis nobis voluptatibus? Quam
                          quidem officiis consequuntur enim. Ad nemo eaque
                          voluptatem nihil labore est veniam sed quaerat placeat
                          in magnam voluptate reprehenderit totam incidunt
                          voluptates accusantium minima aspernatur earum
                          officiis, animi, ullam quibusdam!
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Hardscapes
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          className={classes.body}
                          paragraph
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Dolorem et cumque non reprehenderit, aspernatur
                          inventore itaque omnis repellat modi asperiores quam
                          numquam voluptate officia sapiente perspiciatis qui
                          repellendus a earum ullam. Veritatis officiis odit cum
                          eligendi est perferendis corporis ipsa impedit
                          delectus quidem, porro aspernatur, cumque laboriosam
                          molestias quibusdam eum?
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Fire Pits & Fire/Water Features
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          className={classes.body}
                          paragraph
                        >
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Sequi ad incidunt doloribus nam repellat ipsam
                          sapiente minima cumque quae, dolores deleniti
                          reiciendis eaque molestias sit veritatis. Dolor
                          quibusdam ut recusandae repellat dolores eveniet
                          asperiores incidunt, adipisci unde commodi tempore
                          provident harum debitis totam odit! Voluptatibus,
                          sint. Ab maxime accusamus eius?
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Bridges, Walkways & Decks
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          className={classes.body}
                          paragraph
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Animi incidunt harum facere accusantium
                          excepturi architecto. Quia ad a veritatis cumque
                          commodi suscipit voluptatum accusantium odit possimus
                          temporibus error minima necessitatibus illo nisi nihil
                          esse deleniti libero numquam, iste voluptate quasi
                          inventore animi veniam similique. Qui vel aliquid
                          doloribus quisquam voluptatibus, quis, vitae maxime
                          voluptatem, possimus saepe cum. Dolores, voluptas
                          omnis.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Gazebos
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          className={classes.body}
                          paragraph
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Animi incidunt harum facere accusantium
                          excepturi architecto. Quia ad a veritatis cumque
                          commodi suscipit voluptatum accusantium odit possimus
                          temporibus error minima necessitatibus illo nisi nihil
                          esse deleniti libero numquam, iste voluptate quasi
                          inventore animi veniam similique. Qui vel aliquid
                          doloribus quisquam voluptatibus, quis, vitae maxime
                          voluptatem, possimus saepe cum. Dolores, voluptas
                          omnis.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Outdoor Kitchen Designs
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          className={classes.body}
                          paragraph
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Animi incidunt harum facere accusantium
                          excepturi architecto. Quia ad a veritatis cumque
                          commodi suscipit voluptatum accusantium odit possimus
                          temporibus error minima necessitatibus illo nisi nihil
                          esse deleniti libero numquam, iste voluptate quasi
                          inventore animi veniam similique. Qui vel aliquid
                          doloribus quisquam voluptatibus, quis, vitae maxime
                          voluptatem, possimus saepe cum. Dolores, voluptas
                          omnis.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Landscape Design
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          className={classes.body}
                          paragraph
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Animi incidunt harum facere accusantium
                          excepturi architecto. Quia ad a veritatis cumque
                          commodi suscipit voluptatum accusantium odit possimus
                          temporibus error minima necessitatibus illo nisi nihil
                          esse deleniti libero numquam, iste voluptate quasi
                          inventore animi veniam similique. Qui vel aliquid
                          doloribus quisquam voluptatibus, quis, vitae maxime
                          voluptatem, possimus saepe cum. Dolores, voluptas
                          omnis.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid item xs={12} md={4}>
            <Container
              maxWidth={matchesMD ? "xl" : "xs"}
              style={{
                paddingTop: "6rem",
                paddingBottom: matchesSM ? "5rem" : undefined,
              }}
              className={classes.floatRight}
            >
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={12}>
                  <Paper className={classes.paper} elevation={20}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          component={Link}
                          to="/new-installations/"
                          className={classes.cardTitle}
                          gutterBottom
                        >
                          Our Waterscape Services
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "1rem" }}>
                        <List
                          component="nav"
                          aria-label="secondary mailbox folders"
                          style={{ margin: "-1rem" }}
                        >
                          <Divider />
                          <CustomListItem
                            to="/new-installations/"
                            primary="New Installations"
                          />
                          <CustomListItem
                            to="/repairs-renovations/"
                            primary="Repairs & Renovations"
                          />
                          <CustomListItem
                            to="/maintenance/"
                            primary="Maintenance"
                          />
                          <CustomListItem
                            to="/outdoor-living/"
                            primary="Outdoor Living"
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "3rem" }}>
                  <Grid container justifyContent="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      to="/contact/"
                      className={classes.contactBtn}
                    >
                      Contact Today
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "3rem" }}>
                  <Paper className={classes.promoOfferPaper} elevation={0}>
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.promoBorder}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          align="center"
                          className={classes.promoHeader}
                        >
                          FREE Download
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.promoSubHeader}
                          paragraph
                          align="center"
                        >
                          HOW TO MAINTAIN YOUR BACKYARD POND
                        </Typography>
                        <form>
                          <input
                            type="hidden"
                            name="form-name"
                            value="Newsletter Signup"
                          />

                          <TextField
                            className={classes.textField}
                            placeholder="Your email"
                            variant="outlined"
                            id="Email Address"
                            name="Email Address"
                          />
                          <Button
                            type="submit"
                            color="inherit"
                            variant="contained"
                            disableElevation={true}
                            className={classes.promoButton}
                          >
                            Get it!
                          </Button>
                        </form>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "3rem" }}>
                  <SidebarTestimonials />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
