import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"
import OutdoorLivingComponent from "../components/OutdoorLivingComponent"
import PhotoGallery from "../components/PhotoGallery"
// import PricingCTA from "../components/UI/PricingCTA"
// import CallToAction from "../components/CallToAction"
// import Faq from "../components/FaqSection"

// const query = graphql`
//   {
//     contentfulHardscapesGallery {
//       images {
//         gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
//         title
//         id
//       }
//     }
//   }
// `

export default function OutdoorLivingPage() {
  // const {
  //   contentfulHardscapesGallery: { images: imageList },
  // } = useStaticQuery(query)
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Outdoor Living Services"
        description="Reimagine your outdoor living space with Andreatta Waterscapes - specializing in waterscapes & hardscapes since 1998."
      />
      <OutdoorLivingComponent />
      {/* <PhotoGallery imageList={imageList} component={false} /> */}
      {/* <PricingCTA padding={false} /> */}
      {/* <Faq /> */}
    </Layout>
  )
}
